import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';

type Props = {
  children: React.Node,
};

const Page = ({ children }: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  return (
    <div ref={pageRef} className={styles['page']}>
      {children}
    </div>
  );
};

export default Page;
