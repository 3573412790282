import React from 'react';
import styles from './Social.module.scss';

export const FacebookIcon = () => (
      <svg width="19.281" fill="currentColor" height={36} viewBox="0 0 19.281 36">
        <path
          d="M19.627,20.25l1-6.515H14.375V9.507c0-1.782.873-3.52,3.673-3.52h2.842V.44A34.658,34.658,0,0,0,15.846,0C10.7,0,7.332,3.12,7.332,8.769v4.965H1.609V20.25H7.332V36h7.043V20.25Z"
          transform="translate(-1.609)"
        />
      </svg>
);

export const InstagramIcon = () => (
      <svg width="31.518" fill="currentColor" height="31.511" viewBox="0 0 31.518 31.511">
        <path
          d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
          transform="translate(0.005 -2.238)"
        />
      </svg>
);

export const PinterestIcon = () => (
      <svg width={27} fill="currentColor" height="35.094" viewBox="0 0 27 35.094">
        <path
          d="M14.344.457C7.13.457,0,5.266,0,13.05,0,18,2.784,20.813,4.472,20.813c.7,0,1.1-1.941,1.1-2.489,0-.654-1.666-2.046-1.666-4.767A9.5,9.5,0,0,1,13.774,3.9c4.788,0,8.332,2.721,8.332,7.72,0,3.734-1.5,10.737-6.349,10.737a3.122,3.122,0,0,1-3.248-3.08c0-2.658,1.856-5.231,1.856-7.973,0-4.655-6.6-3.811-6.6,1.814a8.26,8.26,0,0,0,.675,3.565c-.97,4.177-2.953,10.4-2.953,14.7,0,1.329.19,2.637.316,3.966.239.267.12.239.485.105C9.83,30.6,9.7,29.651,11.306,23.3c.865,1.645,3.1,2.531,4.873,2.531C23.646,25.833,27,18.555,27,12,27,5.013,20.967.457,14.344.457Z"
          transform="translate(0 -0.457)"
        />
      </svg>
);

const Social = () => null;

// const Social = () => (
//     //     <div className={styles['social']}>
//     //     <a aria-label="Facebook" href="https://facebook.com/elankumaran"> <FacebookIcon /></a>
//     //     <a aria-label="Instagram" href="https://facebook.com/elankumaran">  <InstagramIcon /></a>
//     //     <a aria-label="Pinterest" href="https://facebook.com/elankumaran"> <PinterestIcon /></a>

//     // </div>
//     );

export default Social;